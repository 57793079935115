import { FACADE_SIZES } from './_UIParams';
import OPTIONS_DATA from '../_json/_options_data.json';

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const formatNumber = (value) =>
{
	const num = typeof value === 'number' ? value : parseInt(value, 10);
	if (isNaN(num)) return value;
	return num.toLocaleString('en-US');
};

export const getFilterValue = (key, value) =>
{
	if (key === 'facade-size')
	{
		const size = FACADE_SIZES.find(size => size.id === value);
		return size ? size.label : '';
	}
	else
	{
		return OPTIONS_DATA[key].find(option => option.value === value)?.label || '';
	}
};

export const getImgFacadeSize = (selectedSize, item) =>
{
	for (let i = selectedSize - 1; i >= 0; i--)
	{
		if (item.facades[i] === 1)
		{
			return i + 1;
		}
	}

	return item['facade-size'];
}

export const convertMMToGrids = (mmValue) =>
{
	return mmValue / 1820 * 2;
}

export const convertUnitToGrids = (unit) =>
{
	return [2, 2.5, 4][unit - 1] * 2;
}

export const toBR = (str) =>
{
	str = str.replace(/(\n)/g, '<br/>');
	str = str.replace(/(\[n1\])/g, '<br class="pc-only"/>');
	str = str.replace(/(\[n2\])/g, '<br class="sp-only"/>');
	return str;
}

export const filterByParam = (item, filterKey, filterValues) =>
{
	if (!filterValues || filterValues.length === 0)
	{
		return true;
	}

	const itemValue = item[filterKey];

	if (typeof itemValue === 'string')
	{
		const itemValues = itemValue.split(',');
		return itemValues.some(value => filterValues.includes(parseInt(value)));
	}

	return filterValues.includes(itemValue);
};