import { mergeClasses } from '@griffel/react';

export const LayoutImage = ({id, facadeSize, type = 1, gridNum = 6, hasFreespace = false, className}) =>
{
	className = mergeClasses('layout-img', className);

	const UNIT_SIZES = [27, 36, 54];

	let unit1, unit2;

	if (type == 1)
	{
		unit1 = 1;
		unit2 = 1;
	}
	else
	{
		unit1 = 3;
		//unit2 = 2;
		unit2 = 3;
	}

	const k_img = `${process.env.PUBLIC_URL}/img/layout_main_lyr_${String(id).padStart(3, '0')}_${facadeSize}.png`;
	const bg_img1 = `${process.env.PUBLIC_URL}/img/layout_bg_u${UNIT_SIZES[unit1 - 1]}${(hasFreespace)?'_fs':''}_g${gridNum}.png`;
	const bg_img2 = `${process.env.PUBLIC_URL}/img/layout_bg_u${UNIT_SIZES[unit2 - 1]}${(hasFreespace)?'_fs':''}_g${gridNum}.png`;

	return (
		<figure className={className} data-type={type}>
			<img className="kitchen" src={k_img} />
			{unit1 != unit2 ? (
				<>
					<img className="bg pc-only" src={bg_img1} data-unit={unit1} data-grid={gridNum} />
					<img className="bg sp-only" src={bg_img2} data-unit={unit2} data-grid={gridNum}/>
				</>
			) : (
				<img className="bg" src={bg_img1} data-unit={unit1} data-grid={gridNum} />
			)
			}
		</figure>
	);
};