import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mergeClasses } from '@griffel/react';

const UIModal = ({ isOpen, onClose, contentLabel, className, children }) =>
{
	let portalClassName = mergeClasses('modal-portal', className);
	let overlayClassName = mergeClasses('modal-overlay', className);
	let modalClassName = mergeClasses('modal', className);

	const modalRef = useRef(null);

	useEffect(() =>
	{
		const target = document.querySelector('.modal-container');
		if (isOpen && target)
		{
			//disableBodyScroll(target);
		}

		return () =>
		{
			if (target)
			{
				//enableBodyScroll(target);
			}
		};
	},
	[isOpen]);

	useEffect(() =>
	{
		return () =>
		{
			clearAllBodyScrollLocks();
		};
	},
	[]);

	return (
		<ReactModal
			ref={modalRef}
			contentLabel={contentLabel}
			isOpen={isOpen}
			onRequestClose={onClose}
			className={modalClassName}
			portalClassName={portalClassName}
			overlayClassName={overlayClassName}
			closeTimeoutMS={500}
			ariaHideApp={false}
		>
			{children}
		</ReactModal>
	);
};

export default UIModal;
