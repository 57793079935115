import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { INITIAL_FILTER_STATE } from './_UIParams';
import { deepCopy, getImgFacadeSize, convertUnitToGrids, convertMMToGrids, filterByParam } from './_Utils';

const FILTER_PARAMS = ['facade-size', 'kitchen-type', 'layout-type', 'sink', 'fridge', 'cupboard', 'dining-table'];

const SHOW_INSUFFICIENT_SIZE = false;

const LayoutFilterContext = createContext();

export const useLayoutFilter = () => useContext(LayoutFilterContext);

export const LayoutFilterProvider = ({ children, initialData, isDataLoaded }) =>
{
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [layoutFilters, setLayoutFilters] = useState(INITIAL_FILTER_STATE);

	const [shouldUpdateHistory, setShouldUpdateHistory] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() =>
	{
		if (isDataLoaded)
		{
			setData(initialData);
			filterData(initialData, layoutFilters);
		}
	},
		[isDataLoaded, initialData, layoutFilters]);

	useEffect(() =>
	{
		if (!location.pathname.startsWith('/detail'))
		{
			// Avoid reset on detail screen
			const params = new URLSearchParams(location.search);
			const newFilters = deepCopy(INITIAL_FILTER_STATE);

			for (const key in newFilters)
			{
				const value = params.get(key);
				if (value)
				{
					newFilters[key] = value.split(',').map(Number);
				}
				/*
				else if (key === 'facade-size' && location.pathname === '/')
				{
					// Set default value for facade-size when no URL parameters and path is /
					newFilters[key] = [2];
				}
				*/
			}

			setLayoutFilters(newFilters);
		}
	},
	[location.search, location.pathname]);

	// Update URL when filters change
	useEffect(() =>
	{
		if (shouldUpdateHistory)
		{
			const queryString = generateFilterQueryParams();
			navigate(`?${queryString}`, { replace: false });

			setShouldUpdateHistory(false); // Reset the flag
		}
	},
	[layoutFilters, navigate, shouldUpdateHistory]);

	const updateLayoutFilters = (updates) =>
	{
		setShouldUpdateHistory(true);

		setLayoutFilters(prevLayoutFilters =>
		{
			const newFilters = { ...prevLayoutFilters };

			updates.forEach(([key, values]) =>
			{
				if (key in INITIAL_FILTER_STATE && Array.isArray(values))
				{
					newFilters[key] = values;
				}
				else
				{
					console.warn("Invalid filter update:", key, values);
				}
			});

			return newFilters;
		});
	};

	const filterData = (data, filters) =>
	{
		const selectedSize = filters['facade-size'] ? filters['facade-size'][0] : null; // Assuming single selection for facade size

		if (!selectedSize)
		{
			setFilteredData([]);
			return;
		}

		const filteredResults = data.map(item =>
		{
			let sizeSufficient = true;
			let isFilteredIn = Object.entries(filters).every(([key, values]) =>
			{
				if (key === "facade-size")
				{
					sizeSufficient = values.every(filterValue => filterValue >= item[key]);
					if (SHOW_INSUFFICIENT_SIZE) return true; // Do not filter out, just mark size sufficient
					else return sizeSufficient;
				}
				//return values.length === 0 || values.includes(item[key]);
				return filterByParam(item, key, values);
			});

			const imgFacadeSize = selectedSize ? getImgFacadeSize(selectedSize, item) : item['facade-size'];

			// Setting 'grid-unit-size' based on conditions
			if (sizeSufficient)
			{
				item['grid-unit-size'] = convertUnitToGrids(selectedSize);
			}
			else if (selectedSize > item['facade-size'])
			{
				item['grid-unit-size'] = convertUnitToGrids(selectedSize);
			}
			else
			{
				item['grid-unit-size'] = convertMMToGrids(item['space-w-mm']);
			}

			return isFilteredIn ? { ...item, "size-sufficient": sizeSufficient, 'img-facade-size': imgFacadeSize, "selected-size": selectedSize } : null;
		}
		).filter(item => item !== null);

		setFilteredData(filteredResults);
	};

	const resetFilters = () =>
	{
		const filters = { ...deepCopy(INITIAL_FILTER_STATE) };
		for (let key in filters) filters[key] = [];

		setLayoutFilters(filters);

		navigate('/', { replace: false });
	};

	const generateFilterQueryParams = () =>
	{
		const params = new URLSearchParams();

		FILTER_PARAMS.forEach(key =>
		{
			if (layoutFilters[key] && layoutFilters[key].length > 0)
			{
				const sortedValues = layoutFilters[key].slice().sort((a, b) => a - b);
				params.set(key, sortedValues.join(','));
			}
		});
		return params.toString();
	};

	return (
		<LayoutFilterContext.Provider value={
			{
				isDataLoaded,
				data: initialData,
				filteredData,
				layoutFilters,
				updateLayoutFilters,
				resetFilters,
				generateFilterQueryParams
			}
		}>
			{children}
		</LayoutFilterContext.Provider>
	);
};
