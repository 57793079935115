import React, { useState, useEffect } from 'react';
import { mergeClasses } from '@griffel/react';
import { INITIAL_FILTER_STATE } from './_UIParams';
import { useLayoutFilter } from './_LayoutFilterContext';
import FilterInputGroups from './_FilterInputGroups';

const FilterPane = ({ className = '', onModalOpen, onSubmit, onReset }) =>
{
	const classes = mergeClasses('pane filter', className);

	const { layoutFilters } = useLayoutFilter();

	const [selectedFilters, setSelectedFilters] = useState({ ...INITIAL_FILTER_STATE });

	useEffect(() =>
	{
		setSelectedFilters(layoutFilters);
	},
	[layoutFilters]);

	return (
		<aside id="filter-pane" className={classes}>
			<div className="pane-title">絞り込み条件</div>
			<div className="pane-content">
				<FilterInputGroups
					groupID="filter-pane"
					onModalOpen={onModalOpen}
					selectedFilters={selectedFilters}
					setSelectedFilters={setSelectedFilters}
				/>
				<div className="block btns rows">
					<button className="btn-plain b-br h-m submit" onClick={() => { onSubmit(selectedFilters) }}>
						<div className="btn-label">
							<p>この条件で表示</p>
						</div>
					</button>
					<button className="btn-plain reset" onClick={onReset}>
						<div className="btn-label">
							<p>リセット</p>
						</div>
					</button>
				</div>
			</div>
		</aside>
	);
};

export default FilterPane;
