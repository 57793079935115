import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { mergeClasses } from '@griffel/react';
import { OPTIONS_NAMES, FACADE_SIZES } from './_UIParams';
import OPTIONS_DATA from '../_json/_options_data.json';
import { useLayoutFilter } from './_LayoutFilterContext';
import UIModal from './_UIModal';
import { toBR } from './_Utils';

const ModalFilters = ({ isOpen, onClose, initialSlideKey = 'facade-size' }) =>
{
	const optionsOrder = ['facade-size', 'kitchen-type', 'extra'];
	const extraFilterKeys = ['sink', 'fridge', 'cupboard', 'dining-table'];
	const initialSlideIndex = initialSlideKey === 'extra' ? 2 : optionsOrder.indexOf(initialSlideKey);

	const swiperRef = useRef(null);

	const optionsLength = optionsOrder.length;

	const [currentSlide, setCurrentSlide] = useState(initialSlideIndex);
	const [totalSlides, setTotalSlides] = useState(optionsLength);

	const { layoutFilters, updateLayoutFilters } = useLayoutFilter();
	const [selectedFilters, setSelectedFilters] = useState({ ...layoutFilters });

	useEffect(() =>
	{
		if (isOpen) handleSwiperUpdate();
	},
		[isOpen, initialSlideIndex, optionsLength]);

	useEffect(() =>
	{
		setSelectedFilters({ ...layoutFilters });
	},
		[layoutFilters]);

	const makeOptionItems = (key) => ({
		key: key,
		question: OPTIONS_NAMES[key]?.q,
		options: OPTIONS_DATA[key].map((item, index) => ({
			key: key,
			title: item.label,
			img: `option_pic_${key}_${item.value}.png`
		}))
	});

	// Creating HTML slide contents
	const makeSlideContents = () => optionsOrder.map(key =>
	{
		let optionItems = [];
		let note = null;

		if (key === 'facade-size')
		{
			optionItems = [{
				key: key,
				options: FACADE_SIZES.map((item, i) => ({
					key: key,
					title: item.name,
					description: (
						<div className="option-description">
							<p className="label">間口（サイズ）</p>
							<p className="value">{item.label2}</p>
						</div>
					),
					img: `option_pic_${key}_${i + 1}.png`
				}))
			}];
			note = <p className="note">本システムにおいて、間口と奥行きはイラストで示すそれぞれの箇所の長さを表します。</p>;
		}
		else if (key !== 'extra')
		{
			optionItems = [makeOptionItems(key)];
		}
		else
		{
			// Handle 'extra' key differently if needed
			optionItems = extraFilterKeys.map(makeOptionItems);
		}

		const question = key === 'extra' ? 'こだわり条件を選択してください' : OPTIONS_NAMES[key]?.q;

		return (
			<div key={key} className="page-inner">
				<div className="slide-container">
					<div className="slide-scroll-content">
						<button className="icon-btn btn-close close" onClick={onClose}>
							<p className="icon close" />
						</button>
						<div className="slide-title">
							<p dangerouslySetInnerHTML={{ __html: toBR(question) }} />
							{key !== 'facade-size' && (<p className="title-note">（複数選択可）</p>)}
						</div>
						<div className="slide-content">
							{optionItems.map((optionItem, optionIndex) => (
								<section key={optionIndex} className="q-section">
									{key === 'extra' && optionItem.question && (
										<p className="section-title">{optionItem.question}</p>
									)}
									<div className={mergeClasses('option-items', 'col-' + optionItem.options.length)}>
										{optionItem.options.map((item, itemIndex) => {
											const itemValue = key === 'facade-size' 
											? FACADE_SIZES[itemIndex].id 
											: OPTIONS_DATA[optionItem.key][itemIndex].value;
											return (
											<div
												key={itemIndex}
												className={mergeClasses(
													'layout-option-item', 
													optionItem.key, 
													selectedFilters[optionItem.key]?.includes(itemValue) ? 'selected' : ''
												  )}
												onClick={() => handleOptionToggle(optionItem.key, itemValue)}
											>
												<div className="item-image">
													<figure>
														<img src={`${process.env.PUBLIC_URL}/img/${item.img}`} alt={item.title} />
													</figure>
												</div>
												<div className="item-content">
													<div className="option-title">{item.title}</div>
													{item.description}
												</div>
											</div>
											)
										}
										)}
									</div>
								</section>
							))}
							<button className="btn-plain b-br h-m submit" onClick={() => handleSubmit([key])}>
								<div className="btn-label">
									<p>この条件で表示</p>
								</div>
							</button>
							{note}
						</div>
					</div>
				</div>
				{(key === 'extra') &&
					(
						<div className="page-footer">
							<button className="btn-plain b-br h-m submit" onClick={() => handleSubmit(extraFilterKeys)}>
								<div className="btn-label">
									<p>この条件で表示</p>
								</div>
							</button>
						</div>
					)
				}
			</div>
		);
	});

	// Integrating contents into Swiper slides
	const makeSwiperSlides = (slideContents) => slideContents.map((content, index) =>
	{
		const key = optionsOrder[index];
		const slideClassName = mergeClasses('filter-page', key);
		return (
			<SwiperSlide key={key} className={slideClassName} data-key={key}>
				{content}
			</SwiperSlide>
		);
	});

	const handleNextSlide = () =>
	{
		if (swiperRef.current)
		{
			swiperRef.current.slideNext();
		}
	};

	const handlePrevSlide = () =>
	{
		if (swiperRef.current)
		{
			swiperRef.current.slidePrev();
		}
	};

	const handleSwiperUpdate = () =>
	{
		if (swiperRef.current && swiperRef.current.slides)
		{
			setCurrentSlide(swiperRef.current.activeIndex);
			setTotalSlides(swiperRef.current.slides.length);
		}
		else
		{
			setCurrentSlide(initialSlideIndex);
			setTotalSlides(optionsLength);
		}
	};

	const handleOptionToggle = (key, value) =>
	{
		setSelectedFilters(prevFilters =>
		{
			const updatedFilters = { ...prevFilters };
			if (key === 'facade-size')
			{
				updatedFilters[key] = [value];
			} else
			{
				const index = updatedFilters[key].indexOf(value);
				if (index >= 0)
				{
					updatedFilters[key] = updatedFilters[key].filter(v => v !== value);
				} else
				{
					updatedFilters[key] = [...updatedFilters[key], value];
				}
			}
			return updatedFilters;
		});
	};

	const handleSubmit = (keys) =>
	{
		const filters = keys.map(key => [key, selectedFilters[key]]);
		updateLayoutFilters(filters);
		onClose();
	};

	const slideContents = makeSlideContents();
	const swiperSlides = makeSwiperSlides(slideContents);

	return (
		<UIModal contentLabel="絞り込み条件" isOpen={isOpen} onClose={onClose} className='filters carousel'>
			<Swiper
				onSwiper={(swiper) => swiperRef.current = swiper}
				onSlideChange={handleSwiperUpdate}
				onInit={handleSwiperUpdate}
				initialSlide={initialSlideIndex}
				autoHeight={true}
			>
				{swiperSlides}
			</Swiper>
			<div className="btn-container">
				<button
					className={`btn-icon btn-prev ${currentSlide === 0 ? 'inactive' : ''}`}
					onClick={handlePrevSlide}
				>
					<p className="icon angle-left" />
				</button>
				<button
					className={`btn-icon btn-next ${currentSlide === totalSlides - 1 ? 'inactive' : ''}`}
					onClick={handleNextSlide}
				>
					<p className="icon angle-right" />
				</button>
			</div>
		</UIModal>
	);
};

export default ModalFilters;
