import React from 'react';
import { UICheckbox, UIRadio } from './_UIInputs';
import { FACADE_SIZES } from './_UIParams';
import OPTIONS_DATA from '../_json/_options_data.json';

const FilterInputGroups = ({ groupID, onModalOpen, selectedFilters, setSelectedFilters }) => 
{
	const isCheckboxChecked = (key, value) =>
	{
		return selectedFilters[key]?.includes(value);
	};

	const isRadioSelected = (key, value) =>
	{
		return selectedFilters[key]?.includes(value);
	};

	const handleCheckboxChange = (category, value, isChecked) =>
	{
		setSelectedFilters(prevFilters =>
		{
			const updatedFilters = { ...prevFilters };

			if (isChecked)
			{
				if (!updatedFilters[category]) updatedFilters[category] = [];
				updatedFilters[category].push(value);
			}
			else
			{
				updatedFilters[category] = updatedFilters[category].filter(v => v !== value);
			}

			return updatedFilters;
		});
	};

	const handleRadioChange = (category, value) =>
	{
		setSelectedFilters(prevFilters => ({
			...prevFilters,
			[category]: [value]
		}));
	};

	return (
		<>
			<div className="block filter-group">
				<div className="block-title">
					<p className="title">ダイニングキッチンの間口</p>
					<button
						id="filter-modal-btn-facade-size"
						className="icon-btn modal-btn"
						onClick={() => onModalOpen('facade-size')}
					>
						<p className="icon modal" />
					</button>
				</div>
				<div className="block-content">
					<div className="input-items">
						{FACADE_SIZES.map(item => (
							<UIRadio
								key={groupID + '-facade-size-' + item.id}
								label={item.label}
								groupKey={groupID + '-facade-size'}
								value={item.id}
								checked={isRadioSelected('facade-size', item.id)}
								onChange={(e) => handleRadioChange('facade-size', item.id)}
							/>
						))}
					</div>
				</div>
			</div>

			<div className="block filter-group">
				<div className="block-title">
					<p className="title">キッチンタイプ</p>
					<button
						id="filter-modal-btn-kitchen-type"
						className="icon-btn modal-btn"
						onClick={() => onModalOpen('kitchen-type')}
					>
						<p className="icon modal" />
					</button>
				</div>
				<div className="block-content">
					<div className="input-items">
					{Object.entries(OPTIONS_DATA["kitchen-type"]).map(([index, item]) => (
						<UICheckbox
							key={groupID + '-kitchen-type-' + item.value}
							label={item.label}
							groupKey={groupID + '-kitchen-type'}
							value={item.value}
							checked={isCheckboxChecked('kitchen-type', item.value)}
							onChange={(e) => handleCheckboxChange('kitchen-type', item.value, e.target.checked)}
						/>
					))}
					</div>
				</div>
			</div>

			<button
				className="btn-icon-right extra-filters"
				onClick={() => onModalOpen('extra')}
			>
				<div className="btn-label">
					<p>こだわり条件を表示</p>
				</div>
				<p className="icon modal" />
			</button>
		</>
	);
};

export default FilterInputGroups;
