const INITIAL_FILTER_STATE =
{
	"facade-size": [2],
	"kitchen-type": [],
	"sink": [],
	"fridge": [],
	"cupboard": [],
	"dining-table": [],
};

const FACADE_SIZES =
	[
		{
			id: 2,
			name: "標準",
			min_w: 2.5,
			min: 4550,
			max: 5460
		},
		{
			id: 1,
			name: "コンパクト",
			min_w: 2,
			min: 3640,
			max: 4550
		},
		{
			id: 3,
			name: "ゆったり",
			min_w: 3,
			min: 5460
		}
	];

FACADE_SIZES.forEach((e, i) =>
{
	if (e.id != 3)
	{
		e.label2 = `${addCommas(e.min)}〜${addCommas(e.max)}mm未満`;
		e.label = `${e.name} ： ${e.label2}`;
	} else
	{
		e.label2 = `${addCommas(e.min)}mm以上`;
		e.label = `${e.name} ： ${e.label2}`;
	}
});

function addCommas(num)
{
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const OPTIONS_NAMES =
{
	"facade-size": {
		name: "ダイニングキッチンの間口",
		q: "ダイニングキッチンの間口を[n2]選んでください"
	},
	"kitchen-type": {
		name: "キッチンタイプ",
		q: "キッチンタイプを[n2]選択してください"
	},
	"sink": {
		name: "シンク",
		name2: "シンクの向き",
		q: "シンクの向きを選択してください",
	},
	"fridge": {
		name: "冷蔵庫",
		name2: "冷蔵庫の配置",
		q: "冷蔵庫の配置を選択してください"
	},
	"cupboard": {
		name: "カップボード",
		name2: "カップボードの配置",
		q: "カップボードの配置を選択してください"
	},
	"dining-table": {
		name: "ダイニングテーブル",
		name2: "ダイニングテーブルの配置",
		q: "ダイニングテーブルの配置を選択してください"
	}
}

export { INITIAL_FILTER_STATE, FACADE_SIZES, OPTIONS_NAMES }