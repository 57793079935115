import UIModal from './_UIModal';
import { mergeClasses } from '@griffel/react';

const ModalFreeSpace = ({ isOpen, onClose }) =>
{
	return (
		<UIModal
			contentLabel="フリースペースの活用例"
			isOpen={isOpen}
			onClose={onClose}
			className="free-space basic"
		>
			<div className="modal-container">
				<div className="modal-title">
					<h2>フリースペースの活用例</h2>
				</div>
				<div className="modal-content">
					<div className="block topic-items">
						<div className="topic-item">
							<div className="item-title">カップボードを延長して大容量収納に</div>
							<div className="item-content">
								カップボードのサイズアップ（有料オプション）により収納量を増やすことができます。フルセパレートタイプの場合には広々とした作業スペースとして活用できます。
							</div>
						</div>
						<div className="topic-item">
							<div className="item-title">パントリーを設けてウォークイン収納に</div>
							<div className="item-content">
								パントリーに家電・食材・ゴミ箱など生活感を出さずに使いたいアイテムを収納することで、リビングをすっきりとした印象に保ちながら、家事をスムーズに行うことができます。
							</div>
						</div>
						<div className="topic-item">
							<div className="item-title">造作棚で収納量アップや飾り棚として活用</div>
							<div className="item-content">
								造作棚を設けて収納量を増やすことができます。オープン棚にしてお気に入りの小物や食器を飾る方も増えています。
							</div>
						</div>
						<div className="topic-item">
							<div className="item-title">室内ドアを設けてキッチンとの行き来をスムーズに</div>
							<div className="item-content">
								キッチンからファミリークローゼットや洗面所などの家事スペースへ直接つながる間取りも人気となっています。
							</div>
						</div>
					</div>
				</div>
				<button className="icon-btn btn-close close" onClick={onClose}>
					<p className="icon close" />
				</button>
			</div>
		</UIModal>
	);
};

export default ModalFreeSpace;
