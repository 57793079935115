import React from 'react';
import { useLayoutFilter } from './_LayoutFilterContext';
import { OPTIONS_NAMES } from './_UIParams';
import { getFilterValue } from './_Utils';

const FilterTag = ({ label, value, onRemove }) =>
{
	return (
		<div className="filter-tag" data-label={label} data-value={value}>
			<span className="tag-label">{label}</span>
			<button className="btn icon-btn remove" onClick={() => onRemove(value)}>
				<p className="icon close"></p>
			</button>
		</div>
	);
};

const FilterSelections = () =>
{
	const { layoutFilters, updateLayoutFilters } = useLayoutFilter();

	// Define the order of the filter categories
	const filterOrder = [...Object.keys(OPTIONS_NAMES)];

	const getLabel = (key, value) =>
	{
		const label = OPTIONS_NAMES[key].name;
		let filterValue = getFilterValue(key, value);

		if (key != 'facade-size')
		{
			filterValue = `${label} ： ${filterValue}`;
		}

		return filterValue;
	};

	const handleRemoveFilter = (key, value) =>
	{
		const values = layoutFilters[key].filter(v => v !== value);

		updateLayoutFilters([[key, values]]);
	};

	// Check if any filters are active
	const areFiltersActive = Object.values(layoutFilters).some(values => values.length > 0);

	if (!areFiltersActive)
	{
		return null;
	}

	return (
		<div className="block filter-selections">
			<div className="block-title">
				<p>現在の検索条件</p>
			</div>
			<div className="block-content">
				{filterOrder.map(key => layoutFilters[key]?.map(value => (
                    <FilterTag
                        key={`${key}-${value}`}
                        label={getLabel(key, parseInt(value))}
                        value={parseInt(value)}
                        onRemove={() => handleRemoveFilter(key, parseInt(value))}
                    />
                )))}
			</div>
		</div>
	);
};

export default FilterSelections;
