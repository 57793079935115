import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { deepCopy, formatNumber, getFilterValue, getImgFacadeSize, convertUnitToGrids, convertMMToGrids } from './_Utils';
import { useLayoutFilter } from './_LayoutFilterContext';
import { LayoutImage } from './_LayoutImage';
import ModalFreeSpace from './_ModalFreeSpace';
import LAYOUT_BASICS from '../_json/_layout_basic.json';
import { OPTIONS_NAMES, FACADE_SIZES } from './_UIParams';
import OPTIONS_DATA from '../_json/_options_data.json';

const DetailScreen = () =>
{
	const location = useLocation();
	const navigate = useNavigate();

	const { data: layoutData, layoutFilters, generateFilterQueryParams } = useLayoutFilter();
	
	const [layoutItem, setLayoutItem] = useState(null);
	const [layoutChildren, setLayoutChildren] = useState([]);
	const [isModalFreeSpaceOpen, setIsModalFreeSpaceOpen] = useState(false);

	const [requiredWidth, setRequiredWidth] = useState(0);

	const queryParams = new URLSearchParams(window.location.search);
	const id = queryParams.get('id');
	const selectedFacadeSize = queryParams.get('facade-size');

	useEffect(() =>
	{
		if (id)
		{
			const item2 = layoutData.find(item => item.id === Number(id));
			if (!item2) return;

			const item = deepCopy(item2);

			const imgFacadeSize = selectedFacadeSize ? getImgFacadeSize(selectedFacadeSize, item) : item['facade-size'];

			item['img-facade-size'] = imgFacadeSize;
			item['grid-unit-size'] = convertUnitToGrids(selectedFacadeSize);

			setLayoutItem(item);

			if (item)
			{
				const layoutIds = item['layout-ids'].split(',');
				const children = layoutIds.map(key =>
					LAYOUT_BASICS.find(basic => basic.key === key)
				);
				setLayoutChildren(children);
			}
		}
	},
	[id, layoutData]);

	useEffect(() =>
	{
		window.scrollTo(0, 0);
	},
	[location.pathname]);

	useEffect(() =>
	{
		const w_mm = getRequiredWidth();
		setRequiredWidth(w_mm);
	},
	[layoutItem, layoutFilters]);

	const getRequiredWidth = () =>
	{
		const facadeSizeId = parseInt(selectedFacadeSize);
		const facade = FACADE_SIZES.find(facade => facade.id === facadeSizeId);

		const minFacadeWidth = facade ? facade.min : 0;
		const minLayoutWidth = layoutItem ? layoutItem['space-w-mm'] : 0;

		return Math.max(minLayoutWidth, minFacadeWidth);
	};

	const handleBackClick = () =>
	{
		const queryString = generateFilterQueryParams();
		navigate(`/?${queryString}`);
	};

	const openFreeSpaceModal = () =>
	{
		setIsModalFreeSpaceOpen(true);
	};

	const closeFreeSpaceModal = () =>
	{
		setIsModalFreeSpaceOpen(false);
	};

	if (!layoutItem)
	{
		return <div className="message no-id">レイアウトが見つかりませんでした。</div>;
	}

	return (
		<>
			<Helmet>
				<title>間取りプラン詳細｜GRAFTEKT 間取りプランニングツール</title>
			</Helmet>
			<div className="app-screen detail">
				<nav className="screen-nav">
					<div className="container">
						<button className="nav-btn screen-back" onClick={handleBackClick}>
							<div className="btn-label">
								<p className="icon angle-left"></p>
								<span className="text">プラン一覧に戻る</span>
							</div>
						</button>
					</div>
				</nav>
				<main id="main">
					<div className="main-header">
						<div className="container">
							<h1 className="page-title">間取りプラン詳細</h1>
						</div>
					</div>
					<div className="main-col">
						<section className="detail">
							<section className="sub-section detail-main">
								<div className="container">
									<div className="block layout-image">
										<div className="block-image">
											<LayoutImage id={layoutItem.id} type="2" facadeSize={layoutItem['img-facade-size']} gridNum={layoutItem['grid-unit-size']} hasFreespace={layoutItem['has-freespace']} />
											<div className="legend-items">
												<LegendItem colorKey="kitchen">
													<p className="label">キッチン</p>
												</LegendItem>
												<LegendItem colorKey="backset">
													<p className="label">カップボード</p>
												</LegendItem>
												{layoutItem['has-freespace'] && (
													<LegendItem colorKey="free-space">
														<p className="label link" onClick={openFreeSpaceModal}>フリースペース</p>
													</LegendItem>
												)}
											</div>
										</div>
									</div>
									<div className="block layout-info">
										<dl>
											<dt>キッチンレイアウト</dt>
											<dd dangerouslySetInnerHTML={{ __html: layoutItem["layout-name"].replace(/(or) /, "$1 <br className='sp-only'>") }}></dd>
										</dl>
										<dl>
											<dt>最低必要の間口</dt>
											<dd>
												{formatNumber(requiredWidth)}
												<span className="unit">mm以上</span>
											</dd>
										</dl>
										<dl>
											<dt>最低必要の奥行</dt>
											<dd>
												{formatNumber(layoutItem["space-d-mm"])}
												<span className="unit">mm以上</span>
											</dd>
										</dl>
									</div>
									<p className="note">
										※参考例になります。施工の際は必ず建築会社様とご相談ください。
									</p>
								</div>
							</section>
							<section className="sub-section selected">
								<div className="container">
									<div className="block bordered selected-filters">
										<div className="block-title">
											<p>選択プラン</p>
										</div>
										<div className="block-content">
											{Object.keys(OPTIONS_NAMES).map(key => (
												<dl key={key}>
													<dt>
														{OPTIONS_NAMES[key].name2 || OPTIONS_NAMES[key].name}
													</dt>
													<dd>
														{key === "facade-size" ? 
															FACADE_SIZES.find(size => size.id === parseInt(selectedFacadeSize)).label
														:
															//Can be a comma-separated string (array) or a single value
															layoutItem[key].toString().split(',')
															.map(value => parseInt(value))
															.sort((a, b) => 
															OPTIONS_DATA[key].findIndex(option => option.value === a) - 
															OPTIONS_DATA[key].findIndex(option => option.value === b)
															)
															.map(value => getFilterValue(key, value))
															.join('、')
														}
													</dd>
												</dl>
											))}
										</div>
									</div>
								</div>
							</section>
							<section className="sub-section layouts">
								<div className="container">
									<div className="block bordered layout-sample">
										<div className="block-title">
											<p>使用しているキッチンレイアウト</p>
										</div>
										<div className="block-content">
											{layoutChildren.map(child => (
												<div key={child.key} className="selected-layout">
													<div className="item-title">
														{child.name}
													</div>
													<div className="item-content">
														<p className="lead">{child.description}</p>
														<div className="item-images">
															<figure className="render">
																<img src={'img/' + child["pers-img"]} alt={child.name} />
															</figure>
															<figure className="floor-plan">
																<img src={'img/' + child["plan-img"]} alt={child.name} />
															</figure>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</section>
						</section>
						<DetailScreenFooter
							layoutItem={layoutItem}
							selectedFacadeSize={selectedFacadeSize}
							requiredWidth={requiredWidth}
						/>
					</div>
				</main>
				<ModalFreeSpace
					isOpen={isModalFreeSpaceOpen}
					onClose={closeFreeSpaceModal}
				/>
			</div>
		</>
	);
};

const LegendItem = ({ colorKey, children }) => 
{
	return (
		<div className="legend-item" data-key={colorKey}>
			<div className="color-tip" />
			{children}
		</div>
	);
}

const DetailScreenFooter = ({layoutItem, selectedFacadeSize, requiredWidth}) => 
{
    const hasPlanPDF = true;
    const showPDForNoEmail = false;

	const [emailStatus, setEmailStatus] = useState('');
	const [emailInput, setEmailInput] = useState('');
	const [emailFormatError, setEmailFormatError] = useState(false);

	const emailSentRef = useRef(false);

	// Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleSendMailClick = async () =>
	{
		if (!emailPattern.test(emailInput) && !showPDForNoEmail)
		{
			setEmailFormatError(true);
			return;
		}

		setEmailFormatError(false);

		const emailInputContainer = document.querySelector('.email-input');

		if (showPDForNoEmail && !emailInput)
		{
			window.open(getPDFURL(layoutItem, selectedFacadeSize, requiredWidth), '_blank');
		}
		else
		{
			emailInputContainer.classList.add('sending');
			try
			{
				await sendPDFByEmail(emailInput, layoutItem, selectedFacadeSize, requiredWidth);
				setEmailStatus('success');
				setEmailInput(''); // Clear the input field on success
				emailSentRef.current = true; // Indicate that the email was sent successfully
			}
			catch (error)
			{
				setEmailStatus('error');
			}
			finally
			{
				emailInputContainer.classList.remove('sending');
			}
		}
	};

	useEffect(() =>
	{
		const emailInputElement = document.getElementById('pdf-email-address');
		const clearStatusOnUserInteraction = () =>
		{
			if (emailSentRef.current)
			{
				setEmailStatus('');
				emailSentRef.current = false; 
			}
		};

		emailInputElement.addEventListener('change', clearStatusOnUserInteraction);
		emailInputElement.addEventListener('click', clearStatusOnUserInteraction);

		return () =>
		{
			emailInputElement.removeEventListener('change', clearStatusOnUserInteraction);
			emailInputElement.removeEventListener('click', clearStatusOnUserInteraction);
		};
	},
	[]); 

	return (
		<>
			{hasPlanPDF && (
				<section className="plan-pdf">
					<div className="container">
						<div className="section-title">
							<h2>プラン詳細のPDFをメールで受け取る</h2>
						</div>
						<p className="lead">
							メールアドレスを入力して頂くと、本ページのPDFファイルを受け取る事ができます。
							<br />
							ショールームにお越しの際にご持参いただくとスムーズにキッチンの選定ができ、プランも決定いただけます。
						</p>
						<div className="email-input">
							<input
								type="email"
								id="pdf-email-address"
								name="frommail"
								placeholder="メールアドレスを入力してください"
								value={emailInput}
								onChange={(e) => setEmailInput(e.target.value)}
								required
							/>
							<button className="btn-plain b-br" onClick={handleSendMailClick}>
								<div className="btn-label">送信</div>
							</button>
						</div>
						{emailFormatError && 
							<p className="mail-status error validation-error">メールアドレスの形式が正しくありません。</p>
						}
						{emailStatus === 'success' &&
							<p className="mail-status success">メールの送信を完了しました。</p>
						}
						{emailStatus === 'error' &&
							<p className="mail-status error">メールの送信を失敗しました。時間をおいて再度お試しください。</p>
						}
						<p className="note">
							※ご入力いただいたメールアドレスは保存されることはありません。
						</p>
						</div>
				</section>
			)}
			<section className={`foot-links ${hasPlanPDF ? '' : 'pt0'}`}>
				<div className="container">
					<div className="block btns cols">
						<a href="https://graftekt.jp/" target="_blank" className="btn btn-plain external-link-btn b-b">
							<div className="btn-label">
								GRAFTEKT ブランドサイト
								<p className="icon external" />
							</div>
						</a>
						<a href="https://graftekt.jp/showroom" target="_blank" className="btn btn-plain external-link-btn b-b">
							<div className="btn-label">
								GRAFTEKT ショールームを予約
								<p className="icon external" />
							</div>
						</a>
					</div>
				</div>
			</section>
		</>
	);
}

const getPDFURL = (layoutItem, selectedFacadeSize, requiredWidth, email) =>
{
	const baseURL = process.env.REACT_APP_PDF_URL || 'https://tjmkitchen-layoutplan.com/pdf';

	const queryParams = constructQueryParams(layoutItem, selectedFacadeSize, requiredWidth);
	queryParams.append('mail', email);

	return `${baseURL}/index.php?${queryParams}`;
};

// Function to send the PDF by email using a POST request
const sendPDFByEmail = async (email, layoutItem, selectedFacadeSize, requiredWidth) =>
{
	const baseURL = process.env.REACT_APP_PDF_URL || 'https://tjmkitchen-layoutplan.com/pdf';

	const queryParams = constructQueryParams(layoutItem, selectedFacadeSize, requiredWidth);
	queryParams.append('mail', email);

	try
	{
		const response = await axios.post(`${baseURL}/index.php`, queryParams.toString(),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}
		);
		return response.data; // Or handle the response as needed
	}
	catch (error)
	{
		console.error('Error sending PDF by email:', error);
		throw error; // Rethrow or handle error as needed
	}
};

const constructQueryParams = (layoutItem, selectedFacadeSize, requiredWidth) =>
{
	const {
		id = 1,
		'space-d-mm': minD = 0,
		'layout-ids': layoutIDs = 'due',
		'img-facade-size': imgFacadeSize = 1,
		'grid-unit-size': gridNum = 4,
		'has-freespace': hasFreespace = 0,
		'kitchen-type': kitchenType = '',
		sink = 1,
		fridge = 1,
		cupboard = 1,
		'dining-table': diningTable = 1
	} = layoutItem;

	const layoutTypes = layoutIDs.split(',').map(key =>
	{
		const layout = LAYOUT_BASICS.find(layout => layout.key === key);
		return layout ? layout.id : null;
	}
	).filter(id => id !== null).join(',');

	// Convert kitchen-type to string if it's an array
	const kitchenTypeValue = Array.isArray(kitchenType) ? kitchenType.join(',') : kitchenType;

	return new URLSearchParams({
		id,
		'min-w': requiredWidth,
		'min-d': minD,
		'layout-types': layoutTypes,
		'facade-size': selectedFacadeSize,
		'img-facade-size': imgFacadeSize,
		'grid-num': gridNum,
		'has-freespace': hasFreespace,
		'kitchen-type': kitchenTypeValue,
		sink,
		fridge,
		cupboard,
		'dining-table': diningTable
	});
};

export default DetailScreen;
