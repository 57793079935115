import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterPane from './_FilterPane';
import SPFooterFilters from './_SPFooterFilters';
import FilterSelections from './_FilterSelections';
import SearchResults from './_SearchResults';
import ModalHome from './_ModalHome';
import ModalFilters from './_ModalFilters';
import { useLayoutFilter } from './_LayoutFilterContext';

const SearchScreen = () =>
{
	const location = useLocation();
	const navigate = useNavigate();

	const [isModalHomeOpen, setIsModalHomeOpen] = useState(false);
	const [isModalFiltersOpen, setIsModalFiltersOpen] = useState(false);
	const [initialFiltersModalKey, setInitialFiltersModalKey] = useState(null);

	const { updateLayoutFilters, resetFilters } = useLayoutFilter();

	useEffect(() =>
	{
		setIsModalHomeOpen(location.pathname === '/home');
	},
	[location]);

	const closeModalHome = () =>
	{
		setIsModalHomeOpen(false);
		navigate('/');
	};

	const openFiltersModal = (slideKey) =>
	{
		setInitialFiltersModalKey(slideKey);
		setIsModalFiltersOpen(true);
	};

	const closeModalFilters = () =>
	{
		setIsModalFiltersOpen(false);
	}

	const handleSubmit = (selectedFilters) =>
	{
		const filters = Object.entries(selectedFilters).map(([key, values]) => [key, values]);
		updateLayoutFilters(filters);
	};

	const handleReset = () =>
	{
		resetFilters();
	};

	return (
		<>
			<Helmet>
				<title>GRAFTEKT 間取りプランニングツール</title>
			</Helmet>
			<div className="app-screen search">
				<main id="main">
					<div className="main-header">
						<div className="container">
							<h1 className="page-title">間取りプラン一覧</h1>
						</div>
					</div>
					<div className="main-cols">
						<FilterPane
							className="pc-only"
							onModalOpen={openFiltersModal}
							onSubmit={handleSubmit}
							onReset={handleReset}
						/>
						<div className="main-col">
							<FilterSelections />
							<SearchResults />
						</div>
					</div>
				</main>
				<SPFooterFilters
					onModalOpen={openFiltersModal}
					onSubmit={handleSubmit}
					onReset={handleReset}
				/>
				<ModalFilters
					isOpen={isModalFiltersOpen}
					onClose={closeModalFilters}
					initialSlideKey={initialFiltersModalKey}
				/>
				<ModalHome
					isOpen={isModalHomeOpen}
					onClose={closeModalHome}
				/>
			</div>
		</>
	);
};

export default SearchScreen;
