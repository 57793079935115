import React, { useState, useEffect } from 'react';
import FilterInputGroups from './_FilterInputGroups';
import { useLayoutFilter } from './_LayoutFilterContext';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const SPFooterFilters = ({ onModalOpen, onSubmit, onReset }) =>
{
	const { layoutFilters, filteredData } = useLayoutFilter();
	const [selectedFilters, setSelectedFilters] = useState({ ...layoutFilters });
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isDrawerClose, setIsDrawerClose] = useState(false);

	useEffect(() =>
	{
		setSelectedFilters(layoutFilters);
	},
	[layoutFilters]);

	const openDrawer = () =>
	{
		setIsDrawerOpen(true);
		setIsDrawerClose(false);

		const drawerElement = document.querySelector('.screen-footer');
		document.body.classList.add("screen-footer-opened");
		//disableBodyScroll(drawerElement);
	};

	const closeDrawer = () =>
	{
		setIsDrawerOpen(false);
		setIsDrawerClose(true);

		const drawerElement = document.querySelector('.screen-footer');
		document.body.classList.remove("screen-footer-opened");
		//enableBodyScroll(drawerElement);
	};

	const handleSubmit = () =>
	{
		onSubmit(selectedFilters);
		closeDrawer();
	}

	return (
		<div className={`screen-footer sp-only ${isDrawerOpen ? 'opened' : ''} ${isDrawerClose ? 'closed' : ''}`}>
			<div className="footer-inner">
				<section className="filters-list">
					<button className="icon-btn btn-close close" onClick={closeDrawer}>
						<p className="icon close" />
					</button>
					<div className="section-title">
						<p>絞り込み条件</p>
					</div>
					<div className="section-content">
						<FilterInputGroups
							groupID="screen-footer"
							onModalOpen={onModalOpen}
							selectedFilters={selectedFilters}
							setSelectedFilters={setSelectedFilters}
						/>
					</div>
				</section>
				<section className="footer-main">
					<div className="section-title">
						<p className="search-counts">
							該当間取りプラン
							<span className="num">{filteredData.filter(item => item["size-sufficient"]).length}</span>
							件を表示中
						</p>
					</div>
					<div className="block btns cols">
						<button className="btn-plain b-br search" onClick={openDrawer}>
							<div className="btn-label">
								<p>条件から検索する</p>
							</div>
						</button>
						<button className="btn-plain reset" onClick={onReset}>
							<div className="btn-label">
								<p>リセット</p>
							</div>
						</button>
						<button className="btn-plain b-br submit" onClick={handleSubmit}>
							<div className="btn-label">
								<p>この条件で表示</p>
							</div>
						</button>
					</div>
				</section>
			</div>
			<div className="footer-overlay"></div>
		</div>
	);
};

export default SPFooterFilters;
