import React from 'react';

const Footer = ({ filters, data }) =>
{
	return (
		<footer>
			<section className="footer-links">
				<div className="container">
					<div className="link-items">
						<a className="link-item" href="https://graftekt.jp/privacypolicy" target="_blank">プライバシーポリシー</a>
						<a className="link-item" href="https://graftekt.jp/webterm" target="_blank">WEBサイト利用規約</a>
						<a className="link-item" href="https://www.tjmdesign.com/company/" target="_blank">会社概要</a>
					</div>
				</div>
			</section>
			<section className="copyright">
				<div className="container">
					<p className="copyright">©︎ TJM Design Corp.</p>
				</div>
			</section>
		</footer>
	);
};

export default Footer;
