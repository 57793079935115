import React from 'react';
import { mergeClasses } from '@griffel/react';
import OPTIONS_DATA from '../_json/_options_data.json';
import { FACADE_SIZES, OPTIONS_NAMES } from './_UIParams';
import { LayoutImage } from './_LayoutImage';
import { convertUnitToGrids, convertMMToGrids } from './_Utils';

const LayoutItem = ({ item, className, onClick }) =>
{
	const classNames = mergeClasses('layout-item', (item["size-sufficient"]?'':'size-insufficient'), className);

	const renderConditionItems = () =>
	{
		return Object.keys(OPTIONS_NAMES).map(key =>
		{
			if (key === "facade-size" || key === "kitchen-type" || !item[key]) return null;
			const optionName = OPTIONS_NAMES[key].name;
			const optionValue = OPTIONS_DATA[key].find(option => option.value === item[key])?.label;

			return (
				<span key={key} className="filter-item">
					<span className="option-name">{optionName}</span>
					{"："}
					<span className="option-value">{optionValue}</span>
				</span>
			);
		});
	};

	const OVERLAY_SIZE_INSUFFICIENT = 
	(
		<div className="overlay-label size-insufficient">
			<div className="label">
				<p>広さ（間口）が足りません</p>
			</div>
		</div>
	);

	return (
		<div className={classNames} data-id={item.id} data-name={item['layout-name']} onClick={onClick}>
			 {item["size-sufficient"] === false && OVERLAY_SIZE_INSUFFICIENT}
			<div className="item-image">
				<LayoutImage id={item.id} type="1" facadeSize={item['img-facade-size']} gridNum={item['grid-unit-size']} />
			</div>
			<div className="item-content">
				<div className="item-info-entries">
					<dl data-key="facade-size">
						<dt>ダイニングキッチンの間口</dt>
						<dd>
							{FACADE_SIZES.map(facade => (
								<div
									key={facade.id}
									//className={`facade-size ${item['facades'][facade.id - 1] == 1 ? 'active' : ''}`}
									className={`facade-size ${item['facade-size'] <= facade.id ? 'active' : ''}`}
									data-id={facade.id}
									data-name={facade.name}
								>
									<span className="label">{facade.name}</span>
								</div>
							))}
						</dd>
					</dl>
					<dl data-key="kitchen-type">
						<dt>キッチンタイプ</dt>
						<dd>
							<p>{item['layout-name']}</p>
						</dd>
					</dl>
					<dl data-key="conditions">
						<dt>こだわり条件</dt>
						<dd>
							{renderConditionItems()}
						</dd>
					</dl>
				</div>
			</div>
		</div>
	);
};

export default LayoutItem;
