import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useLayoutFilter } from './_LayoutFilterContext';
import LayoutItem from './_LayoutItem';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from '@griffel/react';

const SearchResults = () =>
{
	const { layoutFilters, filteredData } = useLayoutFilter();
	const navigate = useNavigate();

	const pageItemNum = 10; // Number of items to load per page
	const enableScrollerNum = 20; // Threshold to enable infinite scrolling

	const [itemsToShow, setItemsToShow] = useState([]);
	const [hasMoreItems, setHasMoreItems] = useState(true);

	useEffect(() =>
	{
		// Reset items to show when filtered data changes
		setItemsToShow(filteredData.slice(0, pageItemNum));
		setHasMoreItems(filteredData.length > pageItemNum);
	},
	[filteredData]);

	const loadMoreItems = (page) =>
	{
		const moreItems = filteredData.slice(0, page * pageItemNum);
		setItemsToShow(moreItems);
		setHasMoreItems(moreItems.length < filteredData.length);
	};

	const handleLayoutItemClick = (id) =>
    {
        const selectedFacadeSize = layoutFilters['facade-size'] ? layoutFilters['facade-size'][0] : '';
		
        navigate(`/detail?id=${id}&facade-size=${selectedFacadeSize}`);
    };

	const contentClassName = mergeClasses('block-content', itemsToShow.length === 0 ? 'no-results' : '');
	
	const infiniteScrollEnabled = filteredData.length >= enableScrollerNum;

	const loaderRings = Array(4).fill().map((_, index) => <div key={index} className="ring"></div>);
	
	return (
		<div className="block search-results">
			<div className="block-title">
				<p className="search-counts">
					該当間取りプラン
					<span className="num">{filteredData.filter(item => item["size-sufficient"]).length}</span>
					件を表示中
				</p>
			</div>
			<div className={contentClassName}>
				{infiniteScrollEnabled ? (
					<InfiniteScroll
						pageStart={0}
						loadMore={loadMoreItems}
						hasMore={hasMoreItems}
						loader={<div className="loader-ring" key="loader">{loaderRings}</div>}
						className="layout-items"
					>
						{itemsToShow.map(item => (
							<LayoutItem key={item.id} item={item} onClick={() => handleLayoutItemClick(item.id)} />
						))}
					</InfiniteScroll>
				) : (
					<div className="layout-items">
						{filteredData.map(item => (
							<LayoutItem key={item.id} item={item} onClick={() => handleLayoutItemClick(item.id)} />
						))}
					</div>
				)}
				{(itemsToShow.length === 0) && (
					<p className="message no-results">
						条件にマッチするプランが
						<br className="sp-only" />
						見つかりませんでした。
					</p>
				)}
			</div>
		</div>
	);
};

export default SearchResults;
