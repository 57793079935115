import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LayoutFilterProvider, useLayoutFilter } from './_js/_LayoutFilterContext';
import SearchScreen from './_js/_SearchScreen';
import DetailScreen from './_js/_DetailScreen';
import Footer from './_js/_Footer';
import 'swiper/swiper-bundle.css';

function App()
{
	const [layoutData, setLayoutData] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	useEffect(() =>
	{
		fetch('/json/layout_data.json')
			.then(response => response.json())
			.then(data =>
			{
				console.log("[LAYOUT DATA Load Complete]");
				setLayoutData(data);
				setIsDataLoaded(true);
			})
			.catch(error =>
			{
				console.error('Error loading layout data:', error);
				setIsDataLoaded(true);
			});
	}, []);

	return (
		<Router>
			<LayoutFilterProvider initialData={layoutData} isDataLoaded={isDataLoaded}>
				<Routes>
					<Route path="/" element={<SearchScreen />} />
					<Route path="/home" element={<SearchScreen />} />
					<Route path="/detail" element={<DetailScreen />} />
				</Routes>
				<Footer />
			</LayoutFilterProvider>
		</Router>
	);
}

export default App;
