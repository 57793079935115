import { mergeClasses } from '@griffel/react';

const UICheckbox = ({ label, groupKey, value, className, checked, onChange }) =>
{
	const classNames = mergeClasses('checkbox-item input checkbox', className);
	const uniqueId = `checkbox-${groupKey}-${value}`;

	return (
		<div className={classNames}>
			<input
				type="checkbox"
				id={uniqueId}
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={uniqueId}>{label}</label>
		</div>
	);
};

const UIRadio = ({ label, groupKey, value, className, checked, onChange }) =>
{
	const classNames = mergeClasses('radio-item input radio', className);
	const uniqueId = `radio-${groupKey}-${value}`;

	return (
		<div className={classNames}>
			<input
				type="radio"
				id={uniqueId}
				name={groupKey} // Radio buttons in the same group should have the same name
				value={value}
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={uniqueId}>{label}</label>
		</div>
	);
};

export { UICheckbox, UIRadio }