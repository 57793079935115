import UIModal from './_UIModal';

const ModalHome = ({ isOpen, onClose }) =>
{
	return (
		<UIModal
			contentLabel="タイトル画面"
			isOpen={isOpen}
			onClose={onClose}
			className="home basic"
		>
			<div className="modal-container">
				<div className="block title">
					<div className="corporate-logo">
						<img src={`${process.env.PUBLIC_URL}/img/logo_2023.png`} alt="GRAFTEKT"/>
					</div>
					<h1 className="app-title">間取りプランニングツール</h1>
				</div>
				<p className="message">
					条件をお選びいただくことで
					<br/>
					暮らしに合わせたキッチン間取りを表示します。
				</p>
				<button className="btn-plain b-br h-m start" onClick={onClose}>
					<div className="btn-label">
						<p>プランを見つける</p>
					</div>
				</button>
				<p className="note">
					※参考例になります。施工の際は必ず建築会社様と<br className="sp-only"/>ご相談ください。
				</p>
			</div>
		</UIModal>
	);
};

export default ModalHome;